import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import LegalPageContent from 'components/LegalPageContent/LegalPageContent';
import PreFooter from 'components/PreFooter/PreFooter';

const propTypes = {
  data: PropTypes.object.isRequired,
};

class LegalPageTemplate extends React.Component {
  render() {
    const { data, location, pageContext } = this.props;
    return (
      <Layout
        type={data.mdx.frontmatter.layoutType}
        location={location}
        logoLink={data.mdx.frontmatter.logoLink}
      >
        <SEO
          title={data.mdx.frontmatter.title}
          canonical={pageContext.canonicalURL}
          meta={data.mdx.frontmatter.metaTags}
          titleTemplate={
            data.mdx.frontmatter.layoutType !== 'standard' ? '%s' : undefined
          }
        />
        <LegalPageContent data={data.mdx} />
        <PreFooter />
      </Layout>
    );
  }
}
LegalPageTemplate.propTypes = propTypes;

export default LegalPageTemplate;

export const pageQuery = graphql`
  query LegalPageByPath($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        path
        title
        layoutType
        logoLink
        metaTags {
          name
          content
        }
      }
      body
    }
  }
`;
